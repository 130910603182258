<template>
    <div id="app">
        <router-view :key="key"></router-view>
    </div>
</template>

<script>

import en from 'element-ui/lib/locale/lang/en'
    import cn from 'element-ui/lib/locale/lang/zh-CN'
    import locale from 'element-ui/lib/locale'

    export default {
        name: 'app',
        computed: {
            key () {
                return this.$route.name ? this.$route.name + +new Date() : this.$route+ +new Date();
            }
        },
        watch: {
            '$route.query': {
                handler (newValue) {
                    this.$i18n.locale = newValue.lang === 'en-US' ? 'en-US' : 'zh-CN'
                    locale.use(newValue.lang === 'en-US' ? en : cn)
                },
                deep: true
            },
        },
        data () {
            return {
                loopId: ''
            }
        },
        methods: {

        },
        beforeCreate () {
            Date.prototype.format = function (fmt) {
                let that = new Date(this.getTime())
                let offset = this.getTimezoneOffset() / 60 + 8
                that.setHours(that.getHours() + offset)
                let o = {
                    "M+": that.getMonth() + 1,
                    "d+": that.getDate(),
                    "h+": that.getHours(),
                    "m+": that.getMinutes(),
                    "s+": that.getSeconds(),
                    "q+": Math.floor((that.getMonth() + 3) / 3),
                    "S": that.getMilliseconds()
                };
                if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (that.getFullYear() + "").substr(4 - RegExp.$1.length));
                for (var k in o)
                    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
                return fmt;
            };

            Date.prototype.is_valid = function () {
                return !isNaN(this.getTime());
            };
        },
        created() {},
        beforeDestroy() {}
    }
</script>

<style>
    html,body {
        margin: 0;
        height: 100%;
    }

    #app {
        font-family: 'Microsoft YaHei', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        height: 100%;
    }

    ::-webkit-scrollbar {
        display: none;
    }

</style>
