import config from './config'
let md5 = require('js-md5')

export function g () {
    let times1 = Math.ceil(Math.random() * 10)
    let times2 = Math.ceil(Math.random() * 10)
    let result = config.accessToken, date = new Date().format('yyyyMMdd')
    for(let i = 0; i < times1; i++) {
        result = md5(result)
    }
    for(let i = 0; i < times2; i++) {
        date = md5(date)
    }
    return result + date
}

// 通用的保存文件的方法
export function downloadFile(name, blob) {
    const d = document.createElement("a")
    d.href = window.URL.createObjectURL(blob)
    d.download = name
    d.style.display = "none"
    document.body.appendChild(d)
    d.click()
    document.body.removeChild(d)
    window.URL.revokeObjectURL(d.href)
}