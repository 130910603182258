import Vue from 'vue'
import App from './App.vue'
import router from './routers/index'
import ElementUI from 'element-ui'
import ECharts from 'vue-echarts'
import vueHeadful from 'vue-headful';
import config from './config'
import store from './store'
import VueI18n from "vue-i18n";
import enUS from './i18n/en-US'
import zhCN from './i18n/zh-CN'
import url from './libs/url'
import { validate } from "./utils";
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
import 'element-ui/lib/theme-chalk/display.css';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/base.css';

import en from 'element-ui/lib/locale/lang/en'
import cn from 'element-ui/lib/locale/lang/zh-CN'
import locale from 'element-ui/lib/locale'

import * as echarts from "echarts"
import 'echarts-gl'
import 'echarts/lib/chart/bar'
import 'echarts/lib/chart/pie'
import 'echarts/lib/chart/heatmap'
import 'echarts/lib/chart/sunburst'
import 'echarts/lib/chart/effectScatter'
import 'echarts/lib/chart/tree'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/graph'
import 'echarts/lib/chart/map'
import 'echarts/lib/component/visualMap'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/markLine'
import 'echarts/lib/component/markPoint'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/title'
import 'echarts/lib/component/toolbox'
import 'echarts/lib/component/dataZoom'
import 'echarts/theme/infographic'
import 'echarts/theme/vintage'
import 'echarts/theme/roma'
import 'echarts/theme/shine'
import 'echarts/theme/dark'
import 'echarts/extension/bmap/bmap'
import 'echarts/theme/macarons'
import './assets/stylesheets/style.scss'
import worldGeoJsonZh from "@surbowl/world-geo-json-zh"
import chinaGeoJsonZh from "@/assets/others/china.json"

import { registerMap } from "echarts/core";

registerMap('World', worldGeoJsonZh)
registerMap('China', chinaGeoJsonZh)

Vue.config.productionTip = false;

Vue.use(VueI18n);
Vue.use(ElementUI);
Vue.component(CollapseTransition.name, CollapseTransition);
Vue.component('v-chart', ECharts);
Vue.component('vue-headful', vueHeadful);

Vue.config.silent = true;

const messages = {
    'en-US': enUS,
    'zh-CN': zhCN
}

const i18n = new VueI18n({
    locale: url.parseQuery(location.search.replace(/\?/g, '')).lang === 'en-US' ? 'en-US' : 'zh-CN',
    messages
})

locale.use(url.parseQuery(location.search.replace(/\?/g, '')).lang === 'en-US' ? en : cn)

new Vue({
    components: {
        App
    },
    i18n,
    store,
    router,
    render: h => h(App),
}).$mount('#app');
