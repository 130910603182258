let Layout = () => import(/* webpackChunkName: "Nebula" */'../components/layout/MainPageLayout.vue')
let HomePage = () => import(/* webpackChunkName: "Nebula" */'../components/HomePage')
let CountryPage = () => import(/* webpackChunkName: "Nebula" */'../components/CountryPage')


let routes = [{
    name: 'HomePage',
    path: '/',
    component: Layout,
    meta: {
        sign_in_required: false
    },
    children: [{
        name: 'OverviewPage',
        path: '',
        component: HomePage,
        meta: {
            sign_in_required: false
        },
    }, {
        name: 'CountryPage',
        path: 'country/:countryId',
        component: CountryPage,
        meta: {
            sign_in_required: false
        },
    }]
}, {
    path: '*',
    redirect: '/sign_in'
}]

export default routes